import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import Gallery from '../components/flexibleContent/Gallery'
import HeroImage from '../components/flexibleContent/HeroImage'
import Text from '../components/flexibleContent/Text'
import TextImage from '../components/flexibleContent/TextImage'
import TextImageSlider from '../components/flexibleContent/TextImageSlider'
import Video from '../components/flexibleContent/Video'
import Button from '../components/flexibleContent/Button'
import FourImages from '../components/flexibleContent/FourImages'
import Seo from '../components/global/Seo'

export default function NewsPost({data}) {
  const page = data.wpPost
  const flexContent = data.wpPost.addYourOwnContentBlocks.pickAContentBlock
  return (
    <div className='single-post'>
      <h1>{page.title}</h1>
      <Seo title={`News | ${page.title}`} description="With origins dating from 1874, the distinguished Manchester Tennis &amp; Racquet Club (MTRC) has been situated on Blackfriars Road in Salford since 1880. This truly unique members' club is one of Manchester's greatest hidden treasures."  />
      {
        flexContent?.map((flex, index) => {
          console.log(flex)
          
          if(flex.fieldGroupName.includes('ImageTextBlock')){
            return <TextImage key={`flexItem_${index}`} image={flex.image} text={flex.text} />
          }
          if(flex.fieldGroupName.includes('TextBlockImageSlider')){
            return <TextImageSlider key={`flexItem_${index}`} images={flex.imageSlider} text={flex.text} />
          }
          if(flex.fieldGroupName.includes('Video')){
            return <Video key={`flexItem_${index}`} video={flex.videoEmbedCode} heading={flex.heading} />
          }
          if(flex.fieldGroupName.includes('Gallery')){
            return <Gallery key={`flexItem_${index}`} gallery={flex.gallery} heading={flex.heading} />
          }
          if(flex.fieldGroupName.includes('HeroImage')){
            return <HeroImage key={`flexItem_${index}`} image={flex.image}  />
          }
          if(flex.fieldGroupName.includes('FullWidthText')){
            return <Text key={`flexItem_${index}`} text={flex.text}  />
          }
        })
      }
    </div>
  )
}

export const query = graphql`
  query NewsSingleQuery($id: String) {
    wpPost(id:{eq:$id}){
      title
      content
      addYourOwnContentBlocks {
        pickAContentBlock {
          ... on WpPost_Addyourowncontentblocks_PickAContentBlock_HeroImage {
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPost_Addyourowncontentblocks_PickAContentBlock_FullWidthText {
            text
            fieldGroupName
          }
          ... on WpPost_Addyourowncontentblocks_PickAContentBlock_Gallery {
            fieldGroupName
            heading
            gallery {
              caption
              image {
                altText
                publicUrl
                localFile {
                  absolutePath
                  childImageSharp {
                    gatsbyImageData
                    original {
                      src
                    }
                  }
                }
              }
            }
          }
          ... on WpPost_Addyourowncontentblocks_PickAContentBlock_ImageTextBlock {
            fieldGroupName
            text
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPost_Addyourowncontentblocks_PickAContentBlock_TextBlockImageSlider {
            fieldGroupName
            text
            imageSlider {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPost_Addyourowncontentblocks_PickAContentBlock_Video {
            fieldGroupName
            heading
            videoEmbedCode
          }
          ... on WpPost_Addyourowncontentblocks_PickAContentBlock_4Images {
            fieldGroupName
            heading
            images {
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPost_Addyourowncontentblocks_PickAContentBlock_Button {
            buttonText
            fieldGroupName
          }
          ... on WpPost_Addyourowncontentblocks_PickAContentBlock_EventsGrid {
            showEventsGrid
            fieldGroupName
          }
        }
      }
    }
  }
`